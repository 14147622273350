import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/landing_page_feature4.png";
import PricingTable from "../Elements/PricingTable";

export default function BusinessGrowing() {
  return (
    <Wrapper id="contact">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="whiteBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">
                  {" "}
                  Join Businesses growing with the index.
                </h2>

                <PricingTable
                  offers={[
                    { name: "30-day free trial", cheked: true },
                    { name: "Personalized onboarding", cheked: true },
                    { name: "Access all features", cheked: true },
                  ]}
                />
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Request Demo"
                      action={() =>
                        window.open(
                          "https://share.hsforms.com/17z_BEBm1QCu_ys3iwhDXEwcllcd"
                        )
                      }
                      border
                    />
                  </div>
                </ButtonsRow>
              </AddLeft>

              <AddRightInner>
                <div className="flexNullCenter">
                  <AddImgWrapp1 className="flexCenter">
                    <img src={AddImage1} alt="office" />
                  </AddImgWrapp1>
                </div>
              </AddRightInner>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 100%;
  margin: 0 10% 0 10%;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
