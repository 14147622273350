import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";

export default function FullPower() {
  return (
    <Wrapper id="pricing">
      <div
        className="lightBg"
        style={{ padding: "50px 0", backgroundColor: "#bbdefb" }}
      >
        <div className="container">
          <MiddleInfo>
            <h1 className="font40 extraBold"> Start your 30-day free trial</h1>
            <p className="font30" style={{ color: "#1976d2" }}>
              Join startups already growing with the index.
            </p>

            <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
              <div style={{ width: "190px" }}>
                <FullButton
                  title="Request Demo"
                  action={() =>  window.open("https://share.hsforms.com/17z_BEBm1QCu_ys3iwhDXEwcllcd")}
                  border
                />
              </div>
            </ButtonsRow>
          </MiddleInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const MiddleInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
