import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/in_1.png";

export default function Introduction() {
  return (
    <Wrapper id="home">
      <div className="whiteBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <h2 className="font40 extraBold">
                {" "}
                Everything you need to super power your delivery operation
              </h2>
              <p className="font20">
                Powerful and tested on the field features for your customers,
                and all your teams including admins, delivery hub staff, and
                delivery drivers
              </p>

              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Request Demo"
                    action={() =>  window.open("https://share.hsforms.com/17z_BEBm1QCu_ys3iwhDXEwcllcd")}
                    border
                  />
                </div>
                
              </ButtonsRow>
            </AddLeft>
            {/* <AddRight> */}
            <AddRightInner>
              <div className="flexNullCenter">
                <AddImgWrapp1 className="flexCenter">
                  <img src={AddImage1} alt="office" />
                </AddImgWrapp1>
              </div>
            </AddRightInner>
            {/* </AddRight> */}
          </Advertising>
        </div>
      </div>
      {/* </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  margin: 20px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 100%;
  margin: 0 0 0 3% ;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
   
  }
`;
