import React from "react";
import styled from "styled-components";
// Components

import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/Landing_page_feature_3.png";
import PricingTable from "../Elements/PricingTable";

export default function NeverLose() {
  return (
    <Wrapper id="features">
      <div className="whiteBg" style={{ padding: "20px 0 0 0" }}>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <h2 className="font40 extraBold">Never lose a cent, ever!</h2>
              <p className="font18">
                Collecting COD from delivery hubs and drivers seems like a
                nightmare. We have built a process to ensure you are in control
                and never lose a cent ever!.
              </p>

              <PricingTable
                offers={[
                  {
                    name: "Automated and configurable collection reconcilations",
                    cheked: true,
                  },
                  {
                    name: "Track and trace who owes you, how much and when",
                    cheked: true,
                  },
                  {
                    name: "Top to bottom drill down capability for insights",
                    cheked: true,
                  },
                ]}
              />
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Request Demo"
                    action={() =>  window.open("https://share.hsforms.com/17z_BEBm1QCu_ys3iwhDXEwcllcd")}
                    border
                  />
                </div>
                
              </ButtonsRow>
            </AddLeft>
            
            <AddRightInner>
              <div className="flexNullCenter">
                <AddImgWrapp1 className="flexCenter">
                  <img src={AddImage1} alt="office" />
                </AddImgWrapp1>
              </div>
            </AddRightInner>
           
          </Advertising>
        </div>
      </div>
    
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  margin: 20px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 20px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 100%;
  margin: 0 10% 0 10%;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
