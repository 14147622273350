import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage2 from "../../assets/img/landing_page_feature_2.png";
import PricingTable from "../Elements/PricingTable";

export default function PayCOD() {
  return (
    <Wrapper id="features">
      <div className="whiteBg" style={{ padding: "20px 0 0 0" }}>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <ImgWrapper className="flexCenter">
                <img src={AddImage2} alt="add" />
              </ImgWrapper>
            </AddLeft>
            <AddRight>
              <h2 className="font40 extraBold">
                {" "}
                Pay COD to your shippers in seconds
              </h2>
              <p className="font18">
                Our proven workflows do the hard work for you. You will be able
                to pay COD collections to your shippers in seconds, not days!
              </p>
              <PricingTable
                offers={[
                  {
                    name: "Automated and configurable COD reconcilations",
                    cheked: true,
                  },
                  {
                    name: "Make COD payments to shippers in seconds",
                    cheked: true,
                  },
                  {
                    name: "Complete visibility of overall payment status",
                    cheked: true,
                  },
                ]}
              />
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Request Demo"
                    action={() =>
                      window.open(
                        "https://share.hsforms.com/17z_BEBm1QCu_ys3iwhDXEwcllcd"
                      )
                    }
                    border
                  />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 10px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 20px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 90%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  padding: 4% 0 0 0;
  @media (max-width: 860px) {
    text-align: center;
    width: 80%;
    order: 2;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 5% 5% 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 400px) {
    padding: 0 5% 5% 5%;
  }
`;
