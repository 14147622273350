import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
import OptimizeProcess from "../components/Sections/OptimizeProcess";
import PayCOD from "../components/Sections/PayCOD";
import NeverLose from "../components/Sections/NeverLose";
import FullPower from "../components/Sections/FullPower";
import BusinessGrowing from "../components/Sections/BusinessGrowing";
import Introduction from "../components/Sections/Introduction";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Introduction />
      <OptimizeProcess />
      <PayCOD />
      <NeverLose />
      <FullPower />
      <BusinessGrowing />
      <Footer />
    </>
  );
}
